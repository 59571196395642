import './App.css';
import store from './providerStore';
import { Provider } from 'react-redux';
import AppContext from './AppContext';

function App() {
  return (
    <Provider store={store}>
      <AppContext />
    </Provider>
  );
}

export default App;
