import { Card, Flex, Metric, Text } from "@tremor/react";

export default function SimpleKpiCard(props) {

    const { caption, metric, color } = props

    return (
        <Card key={caption} decoration={color && 'top'} decorationColor={color}>
            <Flex justifyContent="start" className="space-x-4">
                <div className="truncate">
                    <Text>{caption}</Text>
                    <Metric className="truncate">{metric}</Metric>
                </div>
            </Flex>
        </Card>
    );
}