export const initialKpiProduct = () => ({
    listAllSalesProduct: [],
    currentMonthSalesProduct: [],
    lastMonthSalesProduct: [],
});

function groupByMonth(data) {
    const groupedData = {};

    data.forEach((item) => {
        const month = item.mes;
        groupedData[month] = groupedData[month] || { month, records: [] };
        groupedData[month].records.push(item);
    });


    // console.log('groupByMonth', groupedData)
    return Object.values(groupedData);

}

export const kpiProduct = (currentState, action) => {
    const currentDate = new Date(action.selectedDate);
    const currentMonth = currentDate.getMonth() + 1;

    const kpiProduct = {
        listAllSalesProduct: action.kpiProduct,
        selectedDate: currentDate,
        currentDate: currentMonth,
        currentMonth,
        currentDay: currentDate.getDate(),
        date: new Date(),
        isCurrentMonth: currentMonth === currentDate.getMonth() + 1,
        currentMonthSalesProduct: [],
        lastMonthSalesProduct: [],
        loading: action.loading ? action.loading : currentState.loading,
    };

    const groupedData = groupByMonth(kpiProduct.listAllSalesProduct);

    // console.log('kpiProduct-->groupedData-->', groupedData)
    // console.log('kpiProduct-->kpiProduct.currentMonth-->', kpiProduct.currentMonth)


    kpiProduct.currentMonthSalesProduct = [...(groupedData.find(e => e.month === kpiProduct.currentMonth)?.records || [])];
    kpiProduct.lastMonthSalesProduct = [...(groupedData.find(e => e.month !== kpiProduct.currentMonth)?.records || [])];


    // console.log('kpiProduct-->currentMonthSalesProduct-->', kpiProduct.currentMonthSalesProduct)
    return {
        ...currentState,
        kpiProduct,
    };
};
