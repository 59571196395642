export const initialKpiFinancial = () => ({
    listAllFinancialPending: [],
    listAllFinancialNotPending: [],
    currentMonthFinancialPending: [],
    lastMonthFinancialPending: [],
    loading: false,
});

function groupByMonth(data) {
    const groupedData = {};

    data?.forEach((item) => {
        const month = item.mes;
        groupedData[month] = groupedData[month] || { month, records: [] };
        groupedData[month].records.push(item);
    });

    return Object.values(groupedData);
}

export const kpiFinancial = (currentState, action) => {
    const currentDate = new Date(action.selectedDate);
    const currentMonth = currentDate.getMonth() + 1;

    const kpiFinancial = {
        listAllFinancialPending: action.kpiFinancialPending,
        listAllFinancialNotPending: action.kpiFinancialNotPending,
        selectedDate: currentDate,
        currentDate: currentMonth,
        currentMonth,
        currentDay: currentDate.getDate(),
        date: new Date(),
        isCurrentMonth: currentMonth === currentDate.getMonth() + 1,
        currentMonthFinancialPending: [],
        lastMonthFinancialPending: [],
        currentMonthFinancialNotPending: [],
        lastMonthFinancialNotPending: [],
    };

    // console.log('kpiFinancialPending', action.kpiFinancialPending)

    const groupedDataPending = groupByMonth(kpiFinancial.listAllFinancialPending);
    const groupedDataNotPending = groupByMonth(kpiFinancial.listAllFinancialNotPending);

    kpiFinancial.currentMonthFinancialPending = [...(groupedDataPending.find(e => e.month === kpiFinancial.currentMonth)?.records || [])];
    kpiFinancial.lastMonthFinancialPending = [...(groupedDataPending.find(e => e.month !== kpiFinancial.currentMonth)?.records || [])];

    kpiFinancial.currentMonthFinancialNotPending = [...(groupedDataNotPending.find(e => e.month === kpiFinancial.currentMonth)?.records || [])];
    kpiFinancial.lastMonthFinancialNotPending = [...(groupedDataNotPending.find(e => e.month !== kpiFinancial.currentMonth)?.records || [])];


    // console.log('kpiFinancial.currentMonthFinancialPending', kpiFinancial.currentMonthFinancialPending)

    return {
        ...currentState,
        kpiFinancial,
    };
};
