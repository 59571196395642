import { createStore } from "redux";
import { initialKpiSales, kpiSales } from "../dashboard/sales/store";
import { initialKpiProduct, kpiProduct } from "../dashboard/product/store";
import { initialKpiRealTime, kpiRealTime } from "../dashboard/realTime/store";
import { initialKpiChecking, kpiChecking } from "../dashboard/checking/store";
import { initialKpiFinancial, kpiFinancial  } from "../dashboard/financial/store";
import { setLoadingState} from "../dashboard/component/loading/store";

const INITIAL_STATE = {
    kpiSales: initialKpiSales(),
    kpiProduct: initialKpiProduct(),
    kpiRealTime: initialKpiRealTime(),
    kpiChecking: initialKpiChecking(),
    kpiFinancialPending: initialKpiFinancial(),
    loading: { },
}

function reciveAction(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'KPI_SALES':
            return kpiSales(state, action)
        case 'KPI_PRODUCT':
            return kpiProduct(state, action)
        case 'KPI_REALTIME':
            return kpiRealTime(state, action)
        case 'KPI_CHECKING':
            return kpiChecking(state, action)
        case 'KPI_FINANCIAL':
            return kpiFinancial(state, action)
        case 'LOGOUT_USER':
            return INITIAL_STATE ;
        case 'SET_LOADING': {
            return setLoadingState(state, action)
        }
        default:
            return state;
    }
}

const store = createStore(reciveAction);

export default store;