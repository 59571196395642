import { BarChart, Card, Title } from "@tremor/react";
import { dataFormatter } from "../../../utils/helper";


export default function KpiBarChart(props) {
    const { caption, height } = props

    

    const customClassName = `${height || 'h-72'} mt-4`

    return (

        <Card>
            <Title>{caption}</Title>
            <BarChart
                className={customClassName}
                layout={'horizontal'}
                noDataText="Nenhum dado encontrado"
                yAxisWidth={60}
                valueFormatter={dataFormatter}
                {...props}
            />
        </Card>
    );
}