import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 68;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectUnidade({ unidadeList, handleChangeTo }) {
  const [unidadeSelected, setUnidadeSelected] = React.useState([]);

  const isAllSelected = unidadeSelected.length === unidadeList.length;

  const handleChange = (event) => {
    const { target: { value } } = event;
    const hasAll = value.some(item => item === 'all');

    if (hasAll && isAllSelected) {
      setUnidadeSelected([]);
      handleChangeTo([]);
      return;
    } else if (hasAll) {
      const allUnidades = unidadeList.map(unidade => JSON.stringify({ idUnidade: unidade.idUnidade, nome: unidade.nome }));

      setUnidadeSelected(allUnidades);
      handleChangeTo(allUnidades);
      return;
    }

    setUnidadeSelected(value);
    handleChangeTo(value);
  };

  return (
    <div>
      <FormControl fullWidth> {/* Aqui estamos usando fullWidth para ocupar o grid */}
        <InputLabel id="multiple-checkbox-label">Unidades</InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={unidadeSelected}
          onChange={handleChange}
          input={<OutlinedInput fullWidth label="Unidades" />}
          renderValue={(selected) => `${selected?.length} unidade(s) selecionada(s)`}
          MenuProps={MenuProps}
          size="small"
        >
          <MenuItem key="1" value="all">
            <Checkbox checked={isAllSelected} />
            <ListItemText primary="Selecionar todas" />
          </MenuItem>

          {unidadeList.map((unidade) => (
            <MenuItem key={unidade.idUnidade} value={JSON.stringify({ idUnidade: unidade.idUnidade, nome: unidade.nome })}>
              <Checkbox checked={checked(unidade)} />
              <ListItemText primary={getRenderValue(unidade)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );

  function getRenderValue(unidade) {
    const unidadeObj = typeof unidade === 'string' ? JSON.parse(unidade) : unidade;
    return `${unidadeObj.idUnidade} - ${unidadeObj.nome}`;
  }

  function checked(unidade) {
    return unidadeSelected.some(item => item === JSON.stringify({ idUnidade: unidade.idUnidade, nome: unidade.nome }));
  }
}
