import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../service';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
var md5 = require('md5');

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://sischef.com/" target="_blank">
                sischef.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Login = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const [sending, setSending] = useState(false)
    const { enqueueSnackbar } = useSnackbar();


    const handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        const usernameValue = data.get('email')
        const passwordValue = data.get('password')


        const passwordHash = md5(passwordValue);

        if (usernameValue && passwordHash) {
            setSending(true)
            let service = new AuthService();

            service.login(usernameValue, passwordHash).then(resp => {
                setIsLoggedIn(true);
            }).catch(err => {
                enqueueSnackbar('Usuário e/ou senha inválidos.', {
                    preventDuplicate: true,
                    variant: 'error',
                })

                // console.log('Usuário e/ou senha inválidos.', err)
            }).finally(() => {
                setSending(false)
            })
        } else {
            enqueueSnackbar('Usuário e/ou senha não informado.', {
                preventDuplicate: true,
                variant: 'error',
            })
        }
    };

    // Se o usuário estiver logado, redirecione para a tela principal
    if (isLoggedIn) {
        navigate('/');
    }

    return (




        <Grid container component="main" sx={{ height: '100vh' }}>

            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + '/static/images/layout/login-bg.jpg'})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />

            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square alignItems='center'>
                <Box sx={{
                    my: 8,
                    mx: 10,
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Avatar sx={{ width: 86, height: 86 }} src={process.env.PUBLIC_URL + "/logo-sischef.svg"} />

                        <Typography component="h1" variant="h5">
                            Sischef Analytics - Login
                        </Typography>

                    </Box>

                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            // value={'adm.hungaralanches'}
                            id="username"
                            label="Usuário"
                            placeholder="Informe o usuário."
                            name="email"
                            autoComplete="username"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            // value={'160204'}
                            label="Senha"
                            type="password"
                            id="password"
                            placeholder="Informe a senha."
                            autoComplete="password"
                        />

                        <Button
                            type="submit"
                            fullWidth
                            disabled={sending}
                            variant="contained"
                            sx={{ mt: 3 }}
                        >
                            Acessar
                        </Button>

                        <Copyright sx={{ mt: 5 }} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Login;
