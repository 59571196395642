import { Card, Metric, Text, Flex, BadgeDelta, Title } from "@tremor/react";

export default function CardBadgeDeltaChart(props) {

    const { caption, metric, badgeDelta, deltaType, target } = props

    return (
        <Card key={caption}>
            <Flex alignItems="start">
                <Title>{caption}</Title>
                {badgeDelta &&
                    <BadgeDelta deltaType={deltaType}>{`${badgeDelta}%`}</BadgeDelta>}
            </Flex>
            <Flex justifyContent="start" alignItems="baseline" className="truncate space-x-3">
                <Metric>{metric}</Metric>
                <Text className="truncate">de {target}</Text>
            </Flex>
        </Card>
    )
}