export const initialKpiRealTime = () => {
    return ({
        listAllSales: [],
        currentDaySales: [],
        lastDaySales: []

    })
}

function groupByDay(data) {
    const groupedData = {};

    data?.forEach((item) => {
        const day = new Date(item.dataPedido).getDate()

        if (!groupedData[day]) {
            groupedData[day] = {
                day: day,
                records: [],
            };
        }
        groupedData[day].records.push(item);
    });

    return Object.values(groupedData);
}

function groupByOrderTable(data) {
    const groupedData = {};

    data?.forEach((item) => {

        let table = '0'
        if (item.tipoPedido === 'MESA') {
            table = item.identificadorPrincipal
        } else {
            table = item.identificadorSecundario || '0'
        }

        if (!groupedData[table]) {
            groupedData[table] = {
                table: table,
                records: [],
            };
        }

        groupedData[table].records.push(item);
    });

    return Object.values(groupedData);
}

export const kpiRealTime = (currentState, action) => {
    let kpiRealTime = {
        listAllSalesToday: [],
        currentDaySales: [],
        lastDaySales: [],
        countTable: 0,
        listOrdersTable: [],
        listOfOpenOrders: [],
    }
   
    const currentDate = new Date(action.selectedDate)
    const listIdentifier = action.listIdentifier

    if (listIdentifier && listIdentifier.length > 0) {
        kpiRealTime.countTable = parseFloat(listIdentifier.find(e => e.tipo === "MESA")?.quantidade, 1);
    }

    kpiRealTime.selectedDate = new Date(action.selectedDate)
    kpiRealTime.currentDate = currentDate.getMonth() + 1
    kpiRealTime.currentMonth = currentDate.getMonth() + 1
    kpiRealTime.currentDay = currentDate.getDate()
    kpiRealTime.date = new Date()

    kpiRealTime.listAllSalesToday = action.kpiToday

    const groupedData = groupByDay(kpiRealTime.listAllSalesToday);

    kpiRealTime.currentDaySales = groupedData.find(e => e.day === kpiRealTime.currentDay)?.records
    kpiRealTime.lastDaySales = groupedData.find(e => e.day !== kpiRealTime.currentDay)?.records

    const listOfOpenOrders = action.kpiRealTime

    if (listOfOpenOrders && listOfOpenOrders.length > 0) {
        kpiRealTime.listOfOpenOrders = listOfOpenOrders
        kpiRealTime.listOrdersTable = groupByOrderTable(listOfOpenOrders.filter(e => e.tipoPedido === 'MESA' || e.tipoPedido === 'COMANDA'))
    }
   
    return {
        ...currentState,
        kpiRealTime: kpiRealTime
    }
}
