import { BadgeDelta, Card, Flex, Metric, ProgressBar, Text, Title } from "@tremor/react";

// Single KPI card in the demo dashboard with sample inputs
export default function KpiCard(props) {

    const badgeDeltaType = (badgeDelta) => {

        let deltaType = 'unchanged'

        if (badgeDelta > 0) {
            deltaType = 'moderateIncrease'
        } else {
            deltaType = 'moderateDecrease'
        }

        return deltaType
    }
    
    const { caption, metric, valueProgress, badgeDelta, progress, target, color } = props

    return (
        <Card decoration={color && 'top'} decorationColor={color}>
            <Flex alignItems="start">
                <div>
                    <Title>{caption}</Title>
                    <Metric>{metric}</Metric>
                </div>
                {badgeDelta && 
                    <BadgeDelta deltaType={badgeDeltaType(badgeDelta)}>{`${badgeDelta}%`}</BadgeDelta>
                }
                
            </Flex>
            <Flex className="mt-4">
                <Text className="truncate">{`${progress}% ${valueProgress ? '('+ valueProgress + ')': '' }`}</Text>
                <Text>{target}</Text>
            </Flex>
            <ProgressBar value={progress} className="mt-2" />

        </Card>
    );
}