import {
    Card,
    Title,
    Grid,
    Col,
} from "@tremor/react";
import CardBadgeDeltaChart from "../../../component/cardBadgeDelta";
import { OccupationRealTimeKPI, OrderCountRealTimeKPI } from "../../helper";
import { useSelector } from 'react-redux';
import SimpleKpiCard from "../../../component/simpleKpiCard";

export default function CardInfoRealtime(props) {
    const kpiRealTime = useSelector((state) => state.kpiRealTime)
    const cardOccupationKPI = OccupationRealTimeKPI(kpiRealTime)
    const cardorderCountRealTimeKPI = OrderCountRealTimeKPI(kpiRealTime)
 
    return (
        <Card className="mx-auto" decorationColor={cardOccupationKPI.color} decoration="top">
            <Title>Seu negócio agora</Title>
            <div className="mt-4" />
            
            <Grid numItemsSm={1} numItemsMd={2} numItemsLg={2} className="gap-3">
                <Col>
                    <CardBadgeDeltaChart key={0} {...cardOccupationKPI} />
                </Col>
                <Col>
                    <SimpleKpiCard key={1} {...cardorderCountRealTimeKPI} />
                </Col>
            </Grid>
        </Card >
    );
}