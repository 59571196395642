import { format } from 'date-fns';

export const dateFormat = (date, options) => {

    if (!date)
        return date

    
    const date2 = new Date(date)
    
    let { pattern } = { pattern: "dd/MM/yyyy HH:mm:ss", ...options }

    let formated = format(date2, pattern)

    return formated
}