import React, { useState } from 'react';
import { Chip, Button, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const UnidadeChips = ({ unidades }) => {
    const [showAll, setShowAll] = useState(false);

    // Define os chips visíveis: mostra apenas os primeiros 4 se showAll for falso
    // const visibleUnits = showAll ? unidades : unidades.slice(0, 4);

    const UNIDADES_VISIBLE = 3
    const unidadesArray = Array.isArray(unidades) ? unidades : [];
    const visibleUnits = showAll
        ? unidadesArray
        : unidadesArray.length > UNIDADES_VISIBLE
            ? unidadesArray.slice(0, UNIDADES_VISIBLE)
            : unidadesArray;


    return (
        <Box display="flex" alignItems="flex-start" flexWrap="wrap" gap={1}>
            {/* Renderiza os Chips visíveis */}
            {visibleUnits.map((unidade) => (
                <Chip
                    key={unidade.idUnidade}
                    size="small"
                    label={unidade.nome}
                    color="primary"
                    variant="outlined"
                />
            ))}

            {/* Renderiza o botão somente se houver mais de 4 unidades */}
            {unidades.length > 4 && (
                <Box sx={{ ml: 1 }}>
                    <Button
                        size="small"
                        onClick={() => setShowAll((prevShowAll) => !prevShowAll)}
                        endIcon={showAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    >
                        {showAll ? 'Mostrar menos' : 'Mostrar mais'}
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default UnidadeChips;
