import { BadgeDelta, Card, Flex, Metric, Text, Title } from "@tremor/react";

// Single KPI card in the demo dashboard with sample inputs
export default function ComparativeCardKpi(props) {

    const badgeDeltaType = (badgeDelta) => {

        let deltaType = 'unchanged'

        if (badgeDelta > 0) {
            deltaType = 'moderateIncrease'
        } else {
            deltaType = 'moderateDecrease'
        }

        return deltaType
    }

    const { caption, metric, badgeDelta, target, descriptionComparative } = props

    return (
        <Card>
            <Flex alignItems="start">
                <Title>{caption}</Title>
                <BadgeDelta deltaType={badgeDeltaType(badgeDelta)}>{`${badgeDelta}%`}</BadgeDelta>
            </Flex>
            <Flex justifyContent="start" alignItems="baseline" className="space-x-3 truncate">
                <Metric>{`${metric}`}</Metric>
                <Text>{`${descriptionComparative} ${target}`}</Text>
            </Flex>

        </Card>
    );
}