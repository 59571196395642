import { roundToUp } from "round-to";
import { formatNumber, formatNumberToMM } from "../../../utils/number"
import { isMobile } from "react-device-detect";

function sumTotalValue(data) {

    const total = data?.reduce((sum, sale) => {
        // console.log(sale)
        const saleTotal = sale.dataCancelamento ? 0 : parseFloat(sale.valorTotal);
        return sum + saleTotal;
    }, 0);

    return total || 0;
}

function countCustomer(data) {

    const total = data?.reduce((sum, sale) => {
        const saleTotal = sale.dataCancelamento ? 0 : parseFloat(sale.quantidadePessoas);
        return sum + saleTotal;
    }, 0);

    return total || 0;
}

export const OccupationRealTimeKPI = (kpiRealTime) => {
    const existsCurrentDaySales = kpiRealTime && kpiRealTime.listOrdersTable && kpiRealTime.listOrdersTable.length > 0

    let result = {
        caption: 'Mesas ocupadas',
        metric: 0,
        badgeDelta: 0,
        target: 0,
        color: 'yellow',
        deltaType: 'unchanged'
    }

    if (!existsCurrentDaySales) {
        return result
    }

    const metric = kpiRealTime.listOrdersTable.length || 0
    const target = kpiRealTime.countTable || 1; // Garante que `target` nunca será 0
    const badgeDelta = roundToUp((metric / target) * 100, 2);

    result.target = target
    result.metric = metric
    result.badgeDelta = badgeDelta
    result.deltaType = 'unchanged'

    if (badgeDelta > 75) {
        result.deltaType = 'increase'
        result.color = 'green'
    } else if (badgeDelta > 20) {
        result.deltaType = 'moderateIncrease'
        result.color = 'green'
    } else {
        result.deltaType = 'unchanged'
        result.color = 'yellow'
    }

    return result
}
export const OrderCountRealTimeKPI = (kpiRealTime) => {
    const existsOpenOrders = kpiRealTime && kpiRealTime.listOfOpenOrders && kpiRealTime.listOfOpenOrders.length > 0

    let result = {
        caption: 'Pedidos abertos',
        metric: 0,
        // badgeDelta: 0,
        target: 0,
        color: 'yellow',
        deltaType: 'unchanged'
    }

    if (!existsOpenOrders) {
        return result
    }

    const metric = kpiRealTime.listOrdersTable.length || 0
 
    result.metric = metric
    result.deltaType = 'unchanged'
    result.color = ''

    return result
}

export const CanceledOrderCountRealTimeKPI = (kpiRealTime) => {
    const existsOrdersToday = kpiRealTime && kpiRealTime.currentDaySales && kpiRealTime.currentDaySales.length > 0

    // lastDaySales

    let result = {
        caption: 'Cancelados',
        metric: 0,
        color: 'red',
    }

    if (!existsOrdersToday) {
        return result
    }

    const metric = kpiRealTime.currentDaySales.filter(e => e.dataCancelamento).length
    result.metric = metric

    return result
}

export const OrderAverageTicketRealTimeKPI = (sales) => {
    const existsSalesToday = sales && sales.currentDaySales && sales.currentDaySales.length

    let result = {
        caption: 'Ticket médio',
        metric: 0,
        color: 'yellow'
    }

    if (!existsSalesToday) {
        return result
    }

    const currentMonthLength = sales.currentDaySales.length
    const currentMonthValue = sumTotalValue(sales.currentDaySales);
    const currentMetric = currentMonthValue / currentMonthLength

    result.metric = formatNumber(roundToUp(currentMetric, 2))

    return result
}

export const TodayOrderCountRealTimeKPI = (kpiRealTime) => {
    const existsOpenOrders = kpiRealTime && kpiRealTime.listAllSalesToday && kpiRealTime.listAllSalesToday.length > 0

    // lastDaySales

    let result = {
        caption: 'Pedidos',
        metric: 0,
        color: 'indigo',
    }

    if (!existsOpenOrders) {
        return result
    }

    const metric = kpiRealTime.currentDaySales?.length || 0
    result.metric = metric

    return result
}

export const CustomerCountRealTimeKPI = (kpiRealTime) => {
    const existsOpenOrders = kpiRealTime && kpiRealTime.listAllSalesToday && kpiRealTime.listAllSalesToday.length > 0

    let result = {
        caption: 'Clientes atendidos',
        metric: 0,
        color: 'green',
    }

    if (!existsOpenOrders) {
        return result
    }

    const metric = countCustomer(kpiRealTime.currentDaySales)
    result.metric = metric

    return result
}

export const OrderTypeOpenCountKPI = (sales) => {

    const existsRecord = sales && sales.records && sales.records.length > 0

    let result = {
        caption: sales.type,
        metric: 0,
        color: sales.color || 'indigo'

    }

    if (!existsRecord) {
        return result
    }

    const lengthCurrentSales = sales.records.length
    result.metric = isMobile ? formatNumberToMM(lengthCurrentSales) : formatNumber(lengthCurrentSales, 'decimal')

    return result
}

function groupByOrderType(data) {
    const groupedData = {};

    data?.forEach((item) => {
        const orderType = item.tipoPedido

        if (!groupedData[orderType]) {
            groupedData[orderType] = {
                type: orderType,
                records: [],
            };
        }
        groupedData[orderType].records.push(item);
    });

    return Object.values(groupedData);
}

export const InfoRealTimeKPI = (sales) => {
    const existsCurrentDaySales = sales && sales.currentDaySales && sales.currentDaySales.length

    let result = {
        metric: 0,
        badgeDelta: 0,
        list: [],
        target: 0,
        color: 'red'
    }

    let target = 0
    const lastDaySales = sales.lastDaySales
    const existsLastDaySales = lastDaySales && lastDaySales.length && lastDaySales.filter(e => !e.dataCancelamento)

    if (existsLastDaySales) {
        target = sumTotalValue(lastDaySales)
        result.target = target
    }



    if (!existsCurrentDaySales) {
        return result
    }

    const currentDaySales = sales.currentDaySales.filter(e => !e.dataCancelamento)

    if (!currentDaySales) {
        return result
    }

    sales.orderTypeList = groupByOrderType(currentDaySales)

    const metric = sumTotalValue(currentDaySales)
    result.metric = isMobile ? formatNumberToMM(metric) : formatNumber(metric, { style: 'currency' })

    if (existsLastDaySales) {
        result.target = isMobile ? formatNumberToMM(target) : formatNumber(target, { style: 'currency' })
        result.badgeDelta = roundToUp(((metric - target) / target) * 100, 2)
    }

    function filterByType(data, type) {
        return data?.find(item => item?.type?.toUpperCase() === type?.toUpperCase());
    }

    function extractOrderType(data) {
        const groups = data.map(item => item.type?.toUpperCase());

        return groups.filter((value, index) => groups.indexOf(value?.toUpperCase()) === index);
    }

    const orderTypeList = extractOrderType(sales.orderTypeList)

    let chartData = [];

    orderTypeList.forEach(type => {
        const amount = sumTotalValue(filterByType(sales.orderTypeList, type)?.records)

        chartData.push({
            type: type,
            amount: roundToUp(amount, 2),
            percentage: roundToUp(((amount / metric) * 100), 2)
        });
    })

    chartData.sort((a, b) => b.amount - a.amount);

    result.list = chartData

    return result
}