import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Router from './Routes';

function AppContext()  {
  
  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#f10b5c"
      },
      secondary: {
        main: "#747f86"
      },
      default: {
        main: "#fff"
      }
    }
  });

  // const themeSelected = useSelector(state => state.theme.currentTheme)
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }


  return (
    <div className="App">
        <ThemeProvider
          theme={defaultTheme}>

          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            ref={notistackRef}
            action={(key) => (
              <IconButton size="small" onClick={onClickDismiss(key)}>
                <CloseIcon />
              </IconButton>
            )}
          >
            <Router />
          </SnackbarProvider>

        </ThemeProvider>
    </div>
  );
}

export default AppContext;