import { AreaChart, Card, Title } from "@tremor/react";


export default function KpiAreaChart(props) {
  const { caption } = props

  const dataFormatter = (number) => {
    const options = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 3 }

    return "R$ " + Intl.NumberFormat("pt-BR").format(number, options).toString();
  }

  return (
    <Card>
      <Title>{caption}</Title>
      <AreaChart
        className="h-72 mt-4"
        noDataText="Nenhum dado encontrado"
        yAxisWidth={60}
        valueFormatter={dataFormatter}
        {...props}
      />
    </Card>
  );
}