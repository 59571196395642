export const initialKpiSales = () => {
    return ({
        listAllSales: [],
        currentMonthSales: [],
        lastMonthSales: [],
        loading: false,
    })
}

function groupByMonth(data) {
    if (!Array.isArray(data)) return []; 

    const groupedData = new Map();

    data.forEach((item) => {
        const month = item.mes; 

        if (month === undefined || month === null) {
            console.warn('Item com mês inválido:', item);
            return;
        }

        if (!groupedData.has(month)) {
            groupedData.set(month, { month, records: [] });
        }

        groupedData.get(month).records.push(item);
    });

    return Array.from(groupedData.values());
}



export const kpiSales = (currentState, action) => {
    let kpiSales = {
        listAllSales: [],
        currentMonthSales: [],
        lastMonthSales: [],
        loading: action.loading ?? currentState.loading, 
    }

    const currentDate = action.selectedDate ? new Date(action.selectedDate) : new Date();
    const currentMonth = currentDate.getMonth() + 1;  // Mês de 1 a 12
    const currentDay = currentDate.getDate();
    
    kpiSales.selectedDate = currentDate;
    kpiSales.currentDate = currentMonth;
    kpiSales.currentMonth = currentMonth;
    kpiSales.currentDay = currentDay;
    kpiSales.date = new Date(); 
    kpiSales.isCurrentMonth = currentMonth === kpiSales.date.getMonth() + 1;
    kpiSales.listAllSales = action.kpiSales || [];
    kpiSales.compareWith = action.compareWith

    const groupedData = groupByMonth(kpiSales.listAllSales);

    kpiSales.currentMonthSales = groupedData.find(e => e.month === currentMonth)?.records || [];
    kpiSales.lastMonthSales = groupedData.find(e => e.month !== currentMonth)?.records || [];

    return {
        ...currentState,
        kpiSales
    }
}

