
// Actions
export const SET_LOADING = 'SET_LOADING';

export const setLoading = (key, isLoading) => ({
  type: SET_LOADING,
  payload: { key, isLoading },
});
 

export const setLoadingState = (state, action) => {
    const { key, isLoading } = action.payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: isLoading,
      },
    };
  };


// Selectors
export const isLoading = (state, key) => {
    return state.loading[key] !== undefined ? state.loading[key] : false;
};
  
export const getAllLoadings = (state) => {
return { ...state.loading };
};
  
export const hasAnyLoading = (state) => {
return Object.values(state.loading).some((value) => value === true);
};