import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import AuthService from '../../service';

const Profile = () => {
  const service = new AuthService()
  const profile = service.getProfile()

  const user = {
    name: profile? profile.nome : 'NÃO LOGADO',
    avatar: `https://api.dicebear.com/9.x/bottts/svg?seed=${profile ? profile.nome : 'SEM_NOME'}`,
    username: profile ? (profile.username || '--') : '--',
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: '1rem'
    }}>
      <Tooltip title={user.name.substring(0, 15)}>
        <Avatar
          alt="Avatar usuário"
          src={user.avatar}
        />
      </Tooltip>

      <Typography
        variant="h6"
        color="primary">
        {user.name.substring(0, 15)}
      </Typography>

      <Typography variant="body2" color="secondary" noWrap >{user.username}</Typography>
    </Box >
  );
};

export default Profile;