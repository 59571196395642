import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ifAnyGranted } from "./auth";
import Login from "./login";
import Home from "./home";
import AuthService from "./service";
import Sales from "./dashboard/sales";

export const isAuthorized = (role) => {
    if (role) {
        return ifAnyGranted(role)
    }
    return true;
};

const isAuthenticated = (role) => (
    AuthService.loggedIn()
);

function PrivateRoute({ children }) {
    let auth = isAuthenticated();

    if (!auth) {
        // console.log('isAuthenticated', auth)
        return <Navigate to="/login" replace />;
    }

    return children;
}

const Router = () => (

    <BrowserRouter>
        <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
        </Routes>
    </BrowserRouter>

);

export default Router;