
export const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
];

export const weeks = [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "sexta-feira",
    "Sábado"
];

export function countOfOrdersToDate(data, targetDate) {

    return data?.filter(sale => sale.dia <= targetDate).length || 0;
}

export function sumValueOfOrdersToDate(data, targetDate) {
    return data?.reduce((sum, sale) => {
        return sale.dia <= targetDate ? sum + parseFloat(sale.valorTotal) : sum;
    }, 0) || 0;
}

export function getSalesTotalUntilDate(sales, targetDate) {
    return sales.reduce((total, sale) => {
        const saleDate = sale.day;
        return saleDate <= targetDate ? total + parseFloat(sumTotalValue(sale.records)) : total;
    }, 0);
}

export function getSalesTotalDate(sales, targetDate) {
    return sales.reduce((total, sale) => {
        const saleDate = sale.day;
        return saleDate === targetDate ? total + parseFloat(sumTotalValue(sale.records)) : total;
    }, 0);
}

export function sumTotalValue(data) {
    return (data?.reduce((sum, sale) => {
        return sum + (sale.dataCancelamento ? 0 : parseFloat(sale.valorTotal));
    }, 0)) || 0;
}

export function filterRecordsByDate(data, targetDate) {
    if (!Array.isArray(data)) {
        return [];
    }

    // Retorna os dados sem filtrar se targetDate não for informado
    if (!targetDate) {
        return data;
    }

    // Filtra os registros onde a data do item é menor ou igual ao targetDate
    const filteredData = data.filter(item => item.dia <= targetDate);

    return filteredData;
}

export function groupByDate(data) {
    const groupedData = data?.reduce((acc, item) => {
        const day = item.dia;
        if (!acc[day]) {
            acc[day] = { day, records: [] };
        }
        acc[day].records.push(item);
        return acc;
    }, {}) || {};

    return Object.values(groupedData);
}

export function groupByWeek(data) {
    const groupedData = data?.reduce((acc, item) => {
        const week = item.diaSemana;
        if (!acc[week]) {
            acc[week] = { week, records: [] };
        }
        acc[week].records.push(item);
        return acc;
    }, {}) || {};

    return Object.values(groupedData);
}

export function groupByHour(data) {
    const groupedData = data?.reduce((acc, item) => {
        const hour = item.hora;
        if (!acc[hour]) {
            acc[hour] = { hour, records: [] };
        }
        acc[hour].records.push(item);
        return acc;
    }, {}) || {};

    return Object.values(groupedData);
}

export const dataFormatter = (number) => {
    const options = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }

    return "R$ " + Intl.NumberFormat("pt-BR").format(number, options).toString();
}

export function groupByImportType(data) {
    const groupedData = data?.reduce((acc, item) => {
        const importType = item.tipoPedidoDescricao;
        if (!acc[importType]) {
            acc[importType] = { importType, records: [] };
        }
        acc[importType].records.push(item);
        return acc;
    }, {}) || {};

    return Object.values(groupedData);
}

export function groupByTypeOrder(data) {
    const groupedData = data?.reduce((acc, item) => {
        const typeOrder = item.tipoPedido;
        if (!acc[typeOrder]) {
            acc[typeOrder] = { typeOrder, records: [] };
        }
        acc[typeOrder].records.push(item);
        return acc;
    }, {}) || {};

    return Object.values(groupedData);
}


export function sumQuantity(data) {

    const total = data?.reduce((sum, sale) => {
        const saleQuantity = sale.somaNoTotal && parseFloat(sale.quantidade);
        return sum + saleQuantity;
    }, 0);

    return total || 0;
}


